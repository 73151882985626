<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Candidate Support</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manage Candidate Support
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Candidate Name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Enrolment key" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Exam name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Computer Type" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Operating System" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="support.status"
                            :items="status"
                            item-text="text"
                            dense
                            outlined
                            item-value="value"
                            label="Status"
                            required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <button class="btn btn-primary"><i class="fa fa-search"></i> Search Ticket</button>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Enroller Name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Ticket Type</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr>
                    <td class="px-2">
                      <a class=""
                      >Saurav Kumar Paudel</a>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >1223445</span>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >lorem ipsum lliren shue hshd</span>
                    </td>

                    <td>
                      <span class=""
                      >Support</span>
                    </td>
                    <td>
                        <span class="label label-lg label-inline"
                              v-bind:class="`label-light-success`"
                        >Active</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editTicket" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  data(){
    return{
      status: [
          'Active', 'Closed', 'Replied'
      ],
      support: {
        status: ''
      }
    }
  },
  methods: {
    editTicket(){

    }
  }
}
</script>
